<template>
  <div>
    <!-- 发货弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogShipdata" width="25%">
      <div>确定要修改当前物流信息？</div>
      <div style="margin: 5px 0px 15px 0px">
        修改后订单物流信息将会更新，请谨慎操作
      </div>
      物流公司：<el-select
        v-model="getdataed.logisticscom"
        placeholder="请选择"
        style="width: 250px"
      >
        <el-option
          v-for="(item, index) in alltype"
          :key="index"
          :label="item.Name"
          :value="item.id"
        />
      </el-select>
      <div style="margin: 10px"></div>
      物流单号：<el-input
        style="width: 250px"
        v-model="getdataed.Logisticsordin"
        placeholder="请填写"
      ></el-input>
      <div slot="footer" class="text-right" style="margin-top: -30px">
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="dialogShipdata = false">取 消</el-button>
        </el-badge>
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="AddShipment" class="back_green bai"
            >确 定</el-button
          >
        </el-badge>
      </div>
    </el-dialog>
    <!-- 修改发票弹窗 -->
    <el-dialog title="编辑发票类型" :visible.sync="dialogInvoice" width="25%">
      <div>确定修改当前订单客户所需发票类型？</div>
      <div style="margin: 5px 0px 15px 0px">
        当前发票类型为：“{{ getdataed.Invoicetype }}”，请重新选择：
      </div>
      发票类型：<el-select v-model="getdataed.Invoicetype">
        <el-option
          v-for="(item, index) in alltype"
          :key="index"
          :label="item.Name"
          :value="item.id"
        />
      </el-select>
      <div slot="footer" class="text-right" style="margin-top: -20px">
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="dialogInvoice = false">取 消</el-button>
        </el-badge>
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="AddShipment" class="back_green bai"
            >确 定</el-button
          >
        </el-badge>
      </div>
    </el-dialog>
    <!-- 修改收货人信息弹窗 -->
    <el-dialog
      title="编辑收货人信息"
      :visible.sync="dialogConsigne"
      width="30%"
    >
      <div>确定修改当前订单的收货人信息？</div>
      <div style="margin: 5px 0px 15px 0px">
        修改后将按照新的收货人信息进行配送，请谨慎操作。
      </div>
      <el-form :model="getdataed" label-width="90px" class="demo-ruleForm">
        <el-form-item label="收货人：" prop="consignee">
          <el-input
            style="width: 250px"
            v-model="getdataed.consignee"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话号码：" prop="contactnumber">
          <el-input
            style="width: 250px"
            v-model="getdataed.contactnumber"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="地区：">
          <el-cascader
            style="width: 250px"
            :disabled="true"
            v-model="enterplace"
            placeholder="省/市/区"
            :options="options"
            :props="addressProps"
          />
        </el-form-item>
        <el-form-item label="详细地址：" prop="contactnumber">
          <el-input
            type="textarea"
            :rows="2"
            style="width: 250px"
            v-model="getdataed.contactnumber"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-right" style="margin-top: -20px">
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="dialogConsigne = false">取 消</el-button>
        </el-badge>
        <el-badge :value="2" class="item" type="warning">
          <el-button @click="AddShipment" class="back_green bai"
            >确 定</el-button
          >
        </el-badge>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Popupedr",
  props: {
    getdataed: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: "编辑物流信息",
      // 下拉框
      alltype: [
        {
          Name: "选项1",
          id: 1,
        },
      ],
      options: [],
      enterplace: "",
      addressProps: {
        children: "children",
        label: "name",
        value: "id",
      },
      //发票类型弹窗
      dialogInvoice: false,
      //收货人信息弹窗
      dialogConsigne: false,
      //发货（编辑物流信息）弹窗
      dialogShipdata: false,
    };
  },
  created() {},
  methods: {
    AddShipment() {},
  },
};
</script>
<style lang='scss' scoped>
.el-badge__content {
  border-radius: 0px;
  right: 30px;
}
</style>
<style lang="scss" scoped>
</style>
